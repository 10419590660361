<template>
  <validation-observer ref="observer" tag="div" class="edit-field">
    <div class="segment-wrapper">
      <div
        v-for="(fields, rowIndex) in fieldsSegmented"
        class="segment"
      >
        <template v-for="(row, rowIndex) in fields">
          <b-row v-if="checkShow(row)" class="field-row" :class="{ 'field-row-title' : row.type === 'title'}">

            <b-col v-if="['title', 'hr', 'blank'].includes(row.type)" cols="12">
              <template v-if="row.type === 'title'">
                <!--<hr class="mt-0 mb-1 w-100"/>-->
                <h4 class="mb-1 font-weight-bold">{{ $t(row.label) }}</h4>
              </template>

              <template v-else-if="row.type === 'hr'">
                <hr class="mt-1 mb-2 w-100"/>
              </template>

              <template v-else-if="row.type === 'blank'">
                <div class="w-100" :style="row.style">&nbsp;</div>
              </template>
            </b-col>

            <template v-else>
              <!--<b-col cols="12" md="12">-->
              <b-col cols="12" md="3" lg="3" xl="3">
                <div class="field-row-label">
                  {{ row.label ? $t(row.label) : $t(row.col_list.find(e => e.show_label !== false).label) }}
                  <span v-if="typeof(row.rule) === 'string' && row.rule.indexOf('required') !== -1 "
                    style="color: red">*</span>
                  <span v-else-if="typeof(row.rule) === 'object' && row.rule.required === true"
                    style="color: red">*</span>
                </div>
              </b-col>
              <!--<b-col cols="12" md="12" class="d-flex justify-content-start flex-wrap">-->
              <b-col cols="12" md="9" lg="9" xl="9" class="d-flex justify-content-start flex-wrap">
                <b-row class="w-100" no-gutters>
                  <template v-for="(item, itemIndex) in getItems(row)">
                    <b-col
                      v-if="checkShow(item)"
                      :style="item.style"
                      class="field-row-item"
                    >

                      <b-form-group
                        :description="$t(item.description)"
                        :label-sr-only="true"
                      >

                        <template v-if="item.type === 'slot' && item.validate_in_slot === true">
                          <input type="hidden" v-model="rowData[item.name]"/>
                          <slot :name="item.slot" :rowData="rowData" :isEdit="isEdit" :slotConfig="item.slot_config"
                            :rootItem="item"></slot>
                        </template>

                        <template v-else-if="item.type === 'date'">
                          <b-input-group class="flatpickr">
                            <flat-pickr
                              class="form-control"
                              v-model="rowData[item.name]"
                              :config="Object.assign({wrap: true}, item.config)"
                              @on-change="typeof (item.on_change) === 'function' ? item.on_change({
                            selectedDates: arguments[0],
                            dateStr: arguments[1],
                            instance: arguments[2],
                            dateType: item.name
                          }) : () => {}"
                              :disabled="item.edit_disable === true && isEdit"
                            />
                            <b-input-group-append
                              v-if="item.edit_disable !== true && isEdit || !isEdit"
                            >
                              <b-button
                                variant="outline-primary"
                                data-clear
                              >
                                <!--{{ $t('common.clear') }}-->
                                <feather-icon icon="TrashIcon"/>
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t(item.label)"
                            :rules="item.rule"
                            :customMessages="item.custom_messages"
                          >
                            <input type="hidden" :value="rowData[item.name]"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </template>

                        <validation-provider
                          v-else #default="{ errors }"
                          :name="$t(item.label)"
                          :rules="item.rule"
                          :customMessages="item.custom_messages"
                        >

                          <template v-if="item.type === 'slot' && !item.validate_in_slot">
                            <input type="hidden" v-model="rowData[item.name]"/>
                            <slot :name="item.slot" :rowData="rowData" :isEdit="isEdit" :rootItem="item"></slot>
                          </template>

                          <b-input-group
                            v-if="['text', 'number'].includes(item.type)"
                            :append="item.append"
                          >
                            <b-form-input
                              :id="item.name" :type="item.type" v-model="rowData[item.name]"
                              :list="`${item.name}_datalist`"
                              :disabled="item.edit_disable === true && isEdit"
                              :placeholder="item.placeholder"
                              v-b-tooltip.focus.top="item.tooltip"
                            />
                            <datalist :id="`${item.name}_datalist`" v-if="item.datalist && item.datalist.length > 0">
                              <option
                                v-for="opt in item.datalist"
                                :label="opt.label ? opt.label : ''" :value="opt.value ? opt.value : opt"
                              ></option>
                            </datalist>
                          </b-input-group>

                          <template v-if="item.type === 'radio'">
                            <b-form-radio
                              v-for="option in item.options" :name="item.name + '-' + formId" :value="option.value"
                              v-model="rowData[item.name]"
                              :disabled="item.edit_disable === true && isEdit"
                            >
                              {{ option.text }}
                            </b-form-radio>
                          </template>

                          <div style="padding: calc(0.438rem + 1px) 0" v-if="item.type === 'checkbox'">
                            <template v-for="(option, optionIndex) in item.options">
                              <b-form-checkbox
                                v-show="option.fixed !== false || (option.fixed === false && rowData[item.name] && (rowData[item.name].includes(option.value) || rowData[item.name].includes(option.parent_id)))"
                                :name="item.name"
                                :value="option.value"
                                v-model="rowData[item.name]"
                                :class="{'mt-05': optionIndex !== 0, 'ml-2': option.parent_id}"
                                @input="inputCheckbox(item.name, option.parent_id, option.value, item.parent)"
                              >
                                {{ option.text }}
                              </b-form-checkbox>
                            </template>
                          </div>

                          <div
                            v-if="item.type === 'checkboxFlow'"
                            style="padding: calc(0.438rem + 1px) 0; column-gap: 22px; row-gap: 12px;"
                            class="d-flex justify-content-start flex-wrap"
                          >
                            <template v-for="(option, optionIndex) in item.options">
                              <b-form-checkbox
                                v-show="option.fixed !== false || (option.fixed === false && rowData[item.name] && (rowData[item.name].includes(option.value) || rowData[item.name].includes(option.parent_id)))"
                                :name="item.name"
                                :value="option.value"
                                v-model="rowData[item.name]"
                                @input="inputCheckbox(item.name, option.parent_id, option.value, item.parent)"
                                :disabled="(item.add_disable === true && !isEdit) || (item.edit_disable === true && isEdit)"
                              >
                                {{ option.text }}
                              </b-form-checkbox>
                            </template>
                          </div>

                          <template v-if="item.type === 'time'">
                            <b-form-input type="time" v-model="rowData[item.name]"/>
                          </template>

                          <template v-if="item.type === 'timeHours'">
                            <v-select
                              label="text"
                              :options="hour_options"
                              v-model="rowData[item.name]"
                              :reduce="text => text.value"
                              :clearable="false"
                              :searchable="false"
                            >
                              <template #no-options>
                                {{ $t('common.no_options') }}
                              </template>
                            </v-select>
                          </template>

                          <template v-if="item.type === 'select'">
                            <v-select
                              :id="`field_${rowIndex}_${itemIndex}`"
                              :multiple="item.multiple"
                              label="text"
                              :options="item.options"
                              v-model="rowData[item.name]"
                              :reduce="text => text.value"
                              :disabled="(item.add_disable === true && !isEdit) || (item.edit_disable === true && isEdit)"
                              :clearable="item.clearable === true"
                              :searchable="item.searchable === true"
                              @input="onSelectInput(item, arguments[0])"
                            >
                              <template #no-options>
                                {{ $t('common.no_options') }}
                              </template>
                              <template #option="option">
                                <div class="d-flex flex-nowrap align-items-center">
                                  <feather-icon
                                    v-if="option.tooltip"
                                    class="mr-05 d-block"
                                    v-b-tooltip.hover.top="option.tooltip"
                                    icon="InfoIcon"
                                    style="flex: 0 0 auto; font-size: inherit;"
                                  />
                                  <div>{{ option.text }}</div>
                                </div>
                              </template>
                            </v-select>
                          </template>

                          <template v-if="item.type === 'textarea'">
                            <b-form-textarea rows="3" max-rows="8" v-model="rowData[item.name]"/>
                          </template>

                          <small class="text-danger">{{ errors[0] }}</small>

                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </template>
                </b-row>
              </b-col>
            </template>
          </b-row>
        </template>
      </div>
    </div>
    <hr v-if="showHrBeforeSubmitBtn" class="mt-1 mb-2 w-100"/>
    <b-row>
      <b-col cols="12">
        <button-permission
          variant="primary"
          @click="submitForm"
          :permission="permission"
        >
          {{ submitBtnText }}
        </button-permission>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  max,
  digits,
  alphaDash,
  length,
  regex,
} from '@validations'
import common from '@/common'
import ButtonPermission from '@/components/ButtonPermission'
import _ from 'lodash'

export default {
  name: 'EditField2',
  components: {
    ButtonPermission,
    flatPickr,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      default: () => ([]),
    },
    formId: {
      type: Number,
      default: undefined,
    },
    rowData: {
      type: Object,
      default: () => ({}),
    },
    postApi: {
      type: String,
      default: '',
    },
    finishRouter: {
      type: String,
      default: '',
    },
    permission: { type: String },
    submitBtnText: {
      type: String,
      default: function () {
        return this.$t('common.submit')
      },
    },
    submitAction: {
      type: Function,
      default: null,
    },
    showHrBeforeSubmitBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      checkbox_timestamp: 0,
      common: common,
      test: false,
      hour_options: [],
    }
  },
  computed: {
    // For columnar display, group fields by 'column-separator', return a new array where each element is a group of fields.
    fieldsSegmented() {
      function separate(fields) {
        return fields.reduce((result, field) => {
          if (field.type === 'column-separator') result.push([]);
          else result.at(-1).push(field);
          return result;
        }, [[]]).filter(fields => fields.length > 0)
      }

      if (this.fields.some(f => f.type === 'column-separator')) return separate(this.fields)
      return [this.fields, []]

      // if (this.fields.length <= 8) return [this.fields, []]
      // const index = Math.max(8, Math.ceil(this.fields.length * (1 / 2)))
      // return [this.fields.slice(0, index), this.fields.slice(index)]
    }
  },
  methods: {
    resetField(name) {
      this.rowData[name] = ''
    },
    inputCheckbox: function (field_name, parent_id, current_id, parent) {
      if (!event) return
      if (event.type === 'readystatechange') return
      if (this.checkbox_timestamp === event.timeStamp) return
      if (!parent) return

      // checkbox oninput 方法会调用多次，这种方式可以禁止多次调用
      this.checkbox_timestamp = event.timeStamp

      if (parent_id) {
        let flag = false
        for (const i in parent[parent_id]) {
          if (this.rowData[field_name].includes(parent[parent_id][i])) {
            flag = true
          }
        }

        if (flag) {
          this.rowData[field_name] = this.pushElement(this.rowData[field_name], parent_id)
        } else {
          this.rowData[field_name] = this.removeElement(this.rowData[field_name], parent_id)
        }

      } else if (parent[current_id]) {
        for (const i in parent[current_id]) {
          if (this.rowData[field_name].includes(current_id)) {
            this.rowData[field_name] = this.pushElement(this.rowData[field_name], parent[current_id][i])
          } else {
            this.rowData[field_name] = this.removeElement(this.rowData[field_name], parent[current_id][i])
          }
        }
      }
    },

    pushElement(arr, element) {
      if (!arr.includes(element)) {
        arr.push(element)
      }

      return arr
    },

    removeElement(arr, element) {
      const index = arr.indexOf(element)
      if (index > -1) {
        arr.splice(index, 1)
      }

      return arr
    },

    transData: function () {
      for (const i in this.fields) {
        if (this.fields[i].type === 'number') {
          this.rowData[this.fields[i].name] = parseFloat(this.rowData[this.fields[i].name])
        }
        if (this.fields[i].trim === true) {
          this.rowData[this.fields[i].name] = this.rowData[this.fields[i].name].trim()
        }
      }
    },

    submitForm: function () {
      this.transData()
      if (this.$listeners['beforeSubmit']) {
        const that = this
        this.$emit('beforeSubmit', (isReady, data = undefined) => {
          if (isReady === true) {
            that.runSubmit(data)
          }
        })
      } else {
        this.runSubmit()
      }
    },

    runSubmit: function (data = undefined) {
      data = data ? data : this.rowData
      this.$refs.observer.validate()
        .then(success => {
          if (success && !this.submitAction) {
            common.apiPostData(this.postApi, data)
              .then(res => {
                this.$router.push({ name: this.finishRouter })
                if (this.$listeners['afterSubmit']) {
                  this.$emit('afterSubmit', this.rowData, res.data.facilityID)
                }
              })
          } else if (success) {
            this.submitAction(this.rowData, this.postApi, this.finishRouter)
          } else {
            common.showToast({
              title: this.$t('common.form_error'),
              variant: 'danger',
            })
          }
        })
    },

    getItems: function (data) {
      if (data.multi_col) {
        return data.col_list
      } else {
        return [data]
      }
    },
    checkShow: function (item) {
      const Assertion1 = (item.show === true
        || (item.show_value === undefined && this.rowData[item.show])
        || (item.show_value !== undefined && this.rowData[item.show] === item.show_value)
        || (item.show === undefined && item.add_show === undefined && item.edit_show === undefined)
        || (item.show === undefined && item.add_show === true && this.isEdit === false)
        || (item.show === undefined && item.edit_show === true && this.isEdit === true)
        || eval(item.show_exp))
      const Assertion2 = (!item.show_fn || (typeof item.show_fn === 'function' && item.show_fn(this)))
      return Assertion1 && Assertion2
    },

    onSelectInput(item, value) {
      if (item.on_input) {
        item.on_input(this, value)
      }
    },
  },
  created() {
    // initialize time_option
    for (let i = 0; i < 24; i++) {
      const hour = i < 10 ? '0' + i : i
      const time = hour + ':00'
      this.hour_options.push({
        text: time,
        value: time,
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.edit-field {
  > .segment-wrapper {
    row-gap: 2rem;
    column-gap: 4rem;
    display: flex;
    flex-wrap: wrap;
  }

  .segment {
    flex: 1;
    @media (max-width: (map-get($grid-breakpoints, lg))) {
      flex: 0 0 100%;
    }
  }

  .input-group-append .input-group-text {
    min-width: 60px;
  }

  .field-row-title {
    margin-top: 2rem;
  }

  .field-row-title:first-child {
    margin-top: 0;
  }

  // field label
  .col-12:has(>.field-row-label) {
    padding-right: 0;
    @media (max-width: (map-get($grid-breakpoints, lg))) {
      padding: 0 1rem;
    }
  }

  // field content
  .col-12:has(>.field-row-label)~.col-12 {
    padding-left: 1rem;
    @media (max-width: (map-get($grid-breakpoints, lg))) {
      padding: 0 1rem;
    }
  }

  .field-row-label {
    padding: calc(0.438rem + 1px) 0;
    margin-bottom: 0;
    font-weight: bold;
    opacity: 0.8;
    //text-align: right;
    //
    //@media (max-width: (map-get($grid-breakpoints, lg))) {
    //  text-align: left;
    //}
  }

  .field-row-item {
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .vs__dropdown-toggle {
    white-space: nowrap;
  }
}
</style>
